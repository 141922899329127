<template>
    <VueMultiselect v-model="valueSync" v-bind="$attrs" v-on="$listeners" @focus.native="$emit('focus', $event)" role="combobox" :placeholder="$attrs.placeholder" />
</template>

<script>
import VueMultiselect from "@/components/vue-multiselect";

const EventsEnum = Object.freeze({
    VALUE_UPDATE: "input",
});
export default {
    components: {
        VueMultiselect,
    },
    props: {
        fieldData: {
            type: Object,
            require: true,
        },
    },
    computed: {
        valueSync: {
            get() {
                return this.fieldData.value;
            },
            set(val) {
                this.$emit(EventsEnum.VALUE_UPDATE, val);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
[role="combobox"] {
    width: 70%;
}
::v-deep {
    .multiselect__tags {
        min-height: unset;
    }
}
</style>